<template>
  <div>
    <!-- 方案内容 -->
    <div class="MyKeep_content">
      <!-- 方案内容项 -->
      <div
        class="MyKeep_contentItem"
        v-for="(item,index) in MyKeepList"
        :key="index"
        @click="goHomeDetails(item.id)"
      >
        <!-- 内容项标题 -->
        <div class="MyKeep_contentItem_title">{{item.title}}</div>
        <!-- 内容项图片 -->
        <div class="MyKeep_contentItem_img">
          <img :src="item.cover" />
        </div>
        <div class="MyKeep_info">
          <div>
            <div>
              <i class="el-icon-view"></i>
            </div>
            <div>{{item.visit_num}}</div>
          </div>
          <div>
            <div>
              <i class="iconfont icondianzan"></i>
            </div>
            <div>{{item.like_num}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage: 1,
      MyKeepList: [],
    };
  },
  created() {
    this.getMyKeep();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true); // 离开页面移除滚动加载事件
  },
  methods: {
    // 获取我的作品信息
    getMyKeep() {
      this.$axios({
        url: this.$api + "/auth/user/creation",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        params: {
          page: this.currentPage,
          cat: 2,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.MyKeepList = res.data.data.list;
        }
      });
    },
    // 跳转详情页
    goHomeDetails(e){
      this.$router.push({
        path: '/mHomeDetails',
        query:{
          id: e
        }
      })
    },
    // 处理滚动加载的数据
    handleScroll() {
      let scrollTop = document.documentElement.scrollTop; //滚动条的高
      let screenHeight = window.screen.availHeight; //当前屏幕的高
      let documentTop = document.body.scrollHeight; //全部内容的高
      if (scrollTop + screenHeight >= documentTop + 70) {
        this.$axios({
          url: this.$api + "/auth/user/creation",
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("user_token"),
            "Content-Type": "application/json;charset=UTF-8;",
          },
          params: {
            page: (this.currentPage += 1),
            cat: 2,
          },
        }).then((res) => {
          if (res.data.code == 200) {
            let list = res.data.data.list;
            let listArr = this.MyKeepList;
            listArr.push.apply(listArr, list);
            this.MyKeepList = listArr;
          }
        });
      }
    },
  },
};
</script>

<style scoped>
/* 方案主体-方案内容 */
.MyKeep_content {
  margin: 3% auto;
}
.MyKeep_contentItem {
  width: 94%;
  margin: 3% auto;
  padding: 3%;
  background-color: #fff;
}
/* 方案内容-标题 */
.MyKeep_contentItem_title {
  font-size: 16px;
  font-weight: 600;
  margin: 2% 0;
}
/* 方案内容-图片 */
.MyKeep_contentItem_img {
  margin: 2% 0;
  width: 100%;
  border-radius: 3%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.MyKeep_contentItem_img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.MyKeep_info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.MyKeep_info > div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin: 0 2%;
}
.MyKeep_info > div > div {
  margin: 0 3px;
}
.MyKeep_info > div i {
  font-size: 18px;
}
</style>